/* eslint-disable @typescript-eslint/no-unsafe-call */

/* eslint-disable @typescript-eslint/no-unsafe-member-access */

/* eslint-disable @typescript-eslint/ban-ts-comment */
import { Logo } from '../../../components/logo/Logo';
import { Link } from '../../../shared/Link';
import { NavItem } from '../../../shared/data-models';
import '../../../tailwind.css';
import { Dialog, Menu, Transition } from '@headlessui/react';
import {
  Bars3Icon,
  ChevronDownIcon,
  XMarkIcon,
} from '@heroicons/react/24/outline';
import React, { Fragment, HTMLAttributes, useEffect, useState } from 'react';

export interface HeaderProps extends HTMLAttributes<HTMLDivElement> {
  /** Provide an optional custom logo, or the default will be used. */
  logo?: React.ReactElement[] | React.ReactElement | any;
  /** Provide an array of nav items. These will generate the links in your NavBar */
  navItems: NavItem[];
  /** Provide an optional custom action button href. */
  actionButtonHref?: string;
  /** Provide an optional custom action button label. */
  actionButtonLabel?: string;
  /** Provide an aria-label for your Logo */
  logoAriaLabel?: string;
  /** Provide a custom component type for your nav items. Defaults to 'a'. For Gatsby.js or Next.js apps, you'll want to use their `Link` components, respectively. */
  componentType?: React.ElementType;
}

// Please do not use types off of a default export module or else Storybook Docs will suffer.
// see: https://github.com/storybookjs/storybook/issues/9556
/**
 * A custom Header component. <br />
 * Provides a top nav bar with a logo, nav items, and a login button. <br />
 * It is  styled to match the TestifySec brand. <br />
 * It is responsive. <br />
 * It is accessible. <br />
 * It supports dark mode (to test or render in eithrer dark or light mode, change your browser setting for prefers-color-scheme). <br />
 */
export const Header = ({
  navItems,
  actionButtonHref,
  actionButtonLabel,
  logoAriaLabel,
  componentType,
  logo,
}: HeaderProps) => {
  const [isLoaded, setIsLoaded] = useState(false);
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);
  /**
   * This state is used to control the open state of child nav items, which render as dropdowns.
   * NOTE: This works today with a single dropdown, but we'll need to track the state for each dropdown if we add more.
   */
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [selectedChildNavIndex, setChildNavIndex] = useState<number>();

  useEffect(() => {
    setIsLoaded(true);
  }, [isLoaded]);

  const handleOpenChildNav = (index: number) => {
    setIsMenuOpen(!isMenuOpen);
    setChildNavIndex(index);
  };

  const chevronDown = (index: number) => {
    /* @ts-ignore */
    return (
      <ChevronDownIcon
        className={`-mr-1 h-4 w-4 ${
          isMenuOpen && index === selectedChildNavIndex
            ? 'transition-rot-180'
            : 'transition-rot-0'
        }`}
        aria-hidden="true"
      />
    );
  };

  return (
    <header
      key={isLoaded ? 'server-header' : 'client-header'}
      className="sticky top-0 bg-opacity-75 z-50 bg-white text-blue-950 dark:bg-testify-blue border-b border-zinc-300 dark:border-indigo-700"
    >
      <nav
        data-testid="header"
        className="flex items-center justify-between h-24 container-x"
        aria-label="Global"
      >
        <div className="flex lg:flex-1">
          <Link
            item={{ label: 'Home', href: '/' }}
            linkComponentType={componentType}
            className="-m-1.5 p-1.5"
            role="button"
          >
            <Logo logo={logo} ariaLabel={logoAriaLabel} />
          </Link>
        </div>
        <div className="flex lg:hidden">
          <button
            type="button"
            className="-m-2.5 inline-flex items-center justify-center rounded-md p-2.5 text-testify-blue dark:text-white"
            onClick={() => setMobileMenuOpen(true)}
          >
            <span className="sr-only">Open main menu</span>
            {/* @ts-ignore */}
            <Bars3Icon className="h-6 w-6" aria-hidden="true" />
          </button>
        </div>
        <div className="hidden lg:flex h-full lg:gap-x-12">
          {navItems.map((item, index) =>
            item.navItems ? (
              <>
                <Menu
                  as="div"
                  className="flex items-center h-full text-base font-medium selected:text-testify-blue leading-6 selected:text-testify-blue selected:dark:text-white hover:text-testify-blue dark:hover:text-testify-orange text-testify-medieval dark:text-white border-testify-orange"
                  key={item.label}
                >
                  <div className="relative">
                    <Menu.Button
                      className="inline-flex w-full justify-center items-center gap-x-1.5 px-3 py-2"
                      onClick={() => handleOpenChildNav(index)}
                    >
                      {item.label}
                      {chevronDown(index)}
                    </Menu.Button>

                    <Transition
                      as={Fragment as any}
                      enter="transition ease-out duration-100"
                      enterFrom="transform opacity-0 scale-95"
                      enterTo="transform opacity-100 scale-100"
                      leave="transition ease-in duration-75"
                      leaveFrom="transform opacity-100 scale-100"
                      leaveTo="transform opacity-0 scale-95"
                    >
                      <div className="absolute right--3 mt-2 w-56 rounded-md shadow-lg bg-white dark:bg-testify-blue ring-1 ring-black ring-opacity-5">
                        <Menu.Items className="rounded-md focus:outline-none border border-zinc-200">
                          {item.navItems.map((nestedItem) => (
                            <div key={nestedItem.label} className="py-4 pl-2">
                              {/* @ts-ignore */}
                              <Menu.Item>
                                <Link
                                  item={nestedItem}
                                  linkComponentType={componentType}
                                  className="flex items-center h-full text-base font-medium selected:text-testify-blue leading-6 selected:text-testify-blue selected:dark:text-white hover:text-testify-blue dark:hover:text-testify-orange text-testify-medieval dark:text-white border-testify-orange"
                                  activeClassName="selected border-b-4"
                                />
                              </Menu.Item>
                            </div>
                          ))}
                        </Menu.Items>
                      </div>
                    </Transition>
                  </div>
                </Menu>
              </>
            ) : (
              <Link
                key={item.label}
                item={item}
                linkComponentType={componentType}
                className="flex items-center h-full text-base font-medium leading-6 selected:text-testify-blue selected:dark:text-white hover:text-testify-blue dark:hover:text-testify-orange text-testify-medieval dark:text-white border-testify-orange"
                activeClassName="selected border-b-4"
              />
            )
          )}
        </div>
        <div className="hidden lg:flex lg:flex-1 lg:justify-end">
          <Link
            item={{
              label: actionButtonLabel || 'Early Access',
              href: actionButtonHref || '#',
            }}
            className="button-secondary"
            role="button"
            // This is an external link, no custom component type is needed.
          />
        </div>
      </nav>
      <Dialog
        as="div"
        data-testid="mobile-menu"
        className="lg:hidden"
        open={mobileMenuOpen}
        onClose={setMobileMenuOpen}
      >
        <div className="fixed inset-0 z-50" />
        <Dialog.Panel
          className={
            'container-x pb-6 fixed inset-y-0 right-0 z-50 w-full overflow-y-auto bg-white dark:bg-testify-blue dark:text-white sm:max-w-sm sm:ring-1 sm:ring-gray-900/10'
          }
        >
          <div className="h-24 flex items-center justify-between">
            <Link
              item={{ label: 'Home', href: '/' }}
              linkComponentType={componentType}
              className="-m-1.5 p-1.5"
              role="button"
              onClick={() => setMobileMenuOpen(false)}
            >
              <Logo logo={logo} ariaLabel={logoAriaLabel} />
            </Link>
            <button
              type="button"
              className="-m-2.5 rounded-md p-2.5 text-gray-700"
              onClick={() => setMobileMenuOpen(false)}
            >
              <span className="sr-only">Close menu</span>
              {/* @ts-ignore */}
              <XMarkIcon className="h-6 w-6" aria-hidden="true" />
            </button>
          </div>
          <div className="mt-6 flow-root">
            <div className="-my-6 -mx-3 divide-y divide-gray-500/10">
              <div className="space-y-2 py-6">
                {navItems.map((item, index) =>
                  item.navItems ? (
                    <>
                      <Menu
                        as="div"
                        className="flex items-center h-full text-base font-medium leading-6 selected:text-testify-blue selected:dark:text-white hover:text-testify-blue dark:hover:text-testify-orange text-testify-medieval dark:text-white border-testify-orange"
                        key={`mobile-${item.label}`}
                      >
                        <div className="relative">
                          <Menu.Button
                            className="inline-flex w-full justify-center items-center gap-x-1.5 px-3 py-2"
                            onClick={() => handleOpenChildNav(index)}
                          >
                            {item.label}
                            {chevronDown(index)}
                          </Menu.Button>

                          <Transition
                            as={Fragment as any}
                            enter="transition ease-out duration-100"
                            enterFrom="transform opacity-0 scale-95"
                            enterTo="transform opacity-100 scale-100"
                            leave="transition ease-in duration-75"
                            leaveFrom="transform opacity-100 scale-100"
                            leaveTo="transform opacity-0 scale-95"
                          >
                            <div className="absolute right--3 mt-2 w-56 rounded-md shadow-lg bg-white dark:bg-testify-blue ring-1 ring-black ring-opacity-5">
                              <Menu.Items className="rounded-md focus:outline-none border border-zinc-200">
                                {item.navItems.map((nestedItem) => (
                                  <div
                                    key={`mobile-${nestedItem.label}`}
                                    className="py-4 pl-2"
                                  >
                                    {/* @ts-ignore */}
                                    <Menu.Item>
                                      <Link
                                        item={nestedItem}
                                        linkComponentType={componentType}
                                        className="flex items-center h-full text-base font-medium leading-6 selected:text-testify-blue selected:dark:text-white hover:text-testify-blue dark:hover:text-testify-orange text-testify-medieval dark:text-white border-testify-orange"
                                        activeClassName="selected border-b-4"
                                        onClick={() => setMobileMenuOpen(false)}
                                      />
                                    </Menu.Item>
                                  </div>
                                ))}
                              </Menu.Items>
                            </div>
                          </Transition>
                        </div>
                      </Menu>
                    </>
                  ) : (
                    <Link
                      key={`mobile-${item.label}`}
                      item={item}
                      linkComponentType={componentType}
                      className="block px-3 py-2 text-base font-medium leading-7 text-testify-blue dark:text-white hover:text-testify-blue dark:hover:text-testify-orange border-testify-orange"
                      activeClassName="selected border-b"
                      onClick={() => setMobileMenuOpen(false)}
                    />
                  )
                )}
              </div>
              <div className="py-6">
                <Link
                  item={{
                    label: actionButtonLabel || 'Early Access',
                    href: actionButtonHref || '#',
                  }}
                  className="block px-6 py-2 rounded-md text-sm text-testify-blue dark:text-testify-orange font-medium leading-6 border border-testify-blue dark:border-testify-orange"
                  role="button"
                  onClick={() => setMobileMenuOpen(false)}
                  // This is an external link, no custom component type is needed.
                />
              </div>
            </div>
          </div>
        </Dialog.Panel>
      </Dialog>
    </header>
  );
};
